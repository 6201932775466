var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "EditLayout",
    {
      attrs: {
        item: _vm.item,
        "submit-button-text": _vm.isNew ? _vm.$t("create") : _vm.$t("save"),
        "supports-editor": false
      },
      on: {
        submit: function($event) {
          _vm.isNew ? _vm.create() : _vm.edit()
        }
      }
    },
    [
      _c(
        "div",
        { attrs: { slot: "middle" }, slot: "middle" },
        [
          _c("BaseInput", {
            attrs: { label: _vm.$tc("icon") },
            model: {
              value: _vm.item.icon,
              callback: function($$v) {
                _vm.$set(_vm.item, "icon", $$v)
              },
              expression: "item.icon"
            }
          }),
          _c("BaseInput", {
            attrs: { label: _vm.$tc("link") },
            model: {
              value: _vm.item.link,
              callback: function($$v) {
                _vm.$set(_vm.item, "link", $$v)
              },
              expression: "item.link"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "sidebar-middle" }, slot: "sidebar-middle" },
        [
          _c("BaseInput", {
            attrs: { label: _vm.$t("position"), disabled: "" },
            model: {
              value: _vm.item.position,
              callback: function($$v) {
                _vm.$set(_vm.item, "position", $$v)
              },
              expression: "item.position"
            }
          }),
          _c("label", [_vm._v(_vm._s(_vm.$t("indicativeIcon")))]),
          _c(
            "div",
            [
              _c("BaseIcon", {
                attrs: { "icons-group": "fab", icon: "fa-" + _vm.item.icon }
              })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }